import { render, staticRenderFns } from "./MoveStreetsModal.vue?vue&type=template&id=52ad6062&scoped=true&"
import script from "./MoveStreetsModal.vue?vue&type=script&lang=js&"
export * from "./MoveStreetsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ad6062",
  null
  
)

export default component.exports