<template>
  <span class="move-streets" v-if="hasPerm('people.change_street')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Fusionner"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-house-chimney"></i>
          Fusionner 2 rues
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="street1 && street2" style="padding: 5px; background: #ccc; margin-bottom: 20px">
        Voulez-vous fusionner les rues "{{ street1.name }}" et "{{ street2.name }}"?
        <br />
        Attention, les adresses des familles de la rue "{{ street2.name }}"
        seront modifiées et la rue sera supprimée.
        <br />
        Cette action est irréversible.
      </div>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'MergeStreetsModal',
  components: { },
  mixins: [BackendMixin],
  props: {
    street1: Object,
    street2: Object,
  },
  data() {
    return {
      errorText: '',
    }
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    formInvalid() {
      return !((this.street1) && (this.street2))
    },
    modalId() {
      return 'bv-modal-merge-streets'
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    async onSave() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/people/merge-streets/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'street1': this.street1.id,
            'street2': this.street2.id,
          }
          await backendApi.callApi(data)
          let text = ''
          text = 'Les rues ont été fusionnées'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done')
          this.selectedGroup = null
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
